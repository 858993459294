<template>
  <userTemplate>
    <div class="bg-danger font-34 text-center pd-x-5">
      <el-row>
        <el-col
          :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 16, offset: 4 }"
          :lg="{ span: 8, offset: 8 }"
        >
          <div>
            <p class="mg-y-6 text-white">{{ user.name }}</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row class="pd-x-5 bg-primary text-white text-center">
      <el-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 16, offset: 4 }"
        :lg="{ span: 8, offset: 8 }"
        class="h-100 min-height-screen-255 is-flex ai-center js-center"
      >
        <div v-if="viewDescrip">
          <h2 class="font-weight-light">
            {{
              exam != null && exam.step[step.attId - 1].type == "file"
                ? "กรุณาดูภาพประกอบการบรรยายบนหน้าจอ"
                : "กรุณาดูวีดิโอบนหน้าจอ"
            }}
          </h2>
        </div>
        <div v-else>
          <h1 class="font-weight-normal">เตรียมตอบ!</h1>
          <div class="cicle-count mg-x-auto mg-t-1" style="font-size: 104px">
            {{ countTime }}
          </div>
        </div>
      </el-col>
    </el-row>
  </userTemplate>
</template>
<script>
import { HTTP } from "@/service/axios.js";
import userTemplate from "@/template/VoteUserTemplate";
export default {
  components: {
    userTemplate
  },
  props: {
    routeParams: {
      type: Object,
      default: null
    }
  },
  computed: {
    exam() {
      let data = this.$store.state.exam.filter(a => a.no == this.step.qId);
      if (data.length > 0) {
        return data[0];
      } else {
        return null;
      }
    }
  },
  watch: {
    step() {
      if (this.exam.step.length < this.step.attId) {
        this.countdown();
      } else {
      }
    }
  },
  async mounted() {
    this.getState();
    if (this.step.state != "description") {
      this.checkRouterUser(this.step.state);
    }
    this.intervalation = setInterval(() => {
      this.getState();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalation);
  },
  methods: {
    countdown() {
      this.viewDescrip = false;
      if (this.countTime <= 0) {
        this.$router.push("/vote-question").catch(err => {});
        return false;
      }
      setTimeout(() => {
        setTimeout(() => {
          this.countTime--;
          this.countdown();
        }, 1000);
      }, 600);
    }
  },
  data() {
    return {
      countTime: 5,
      viewDescrip: true
    };
  }
};
</script>
